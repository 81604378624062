import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import HomepageHeader from "@components/header/homepage-header"
import AboutHeader from "@components/header/about-header"
import DonateHeader from "@components/header/donate-header"
import BlogHeader from "@components/header/blog-header"
import VolunteerHeader from "@components/header/volunteer-header"
// import ContactHeader from "@components/header/contact-header"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Footer from "@components/footer/footer"
import "bootstrap/dist/css/bootstrap.css"
import "font-awesome/css/font-awesome.min.css"
import "@sass/main.scss"
import DefaultHeader from "./header/default-header"

const Layout = ({ children, location }) => {
  // console.log("location", location)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  // When embedded in an iFrame, sends a postMessage "WEBRIQ_SITE_HAS_LOADED" to parent
  const [hasLoaded, setHasLoaded] = React.useState(false)
  React.useEffect(() => {
    const sendPostMessage = e => {
      if (!hasLoaded) {
        console.log("Sending postMessage: WEBRIQ_SITE_HAS_LOADED")
        window.parent.postMessage("WEBRIQ_SITE_HAS_LOADED", "*")
        console.log("Successfully sent postMessage: WEBRIQ_SITE_HAS_LOADED")
        setHasLoaded(true)
      }
    }

    sendPostMessage()
  }, [hasLoaded])

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}

      {(location && location.pathname === "/" && (
        <HomepageHeader siteTitle={data.site.siteMetadata.title} />
      )) ||
        (location && location.pathname === "/about/" && (
          <AboutHeader siteTitle={data.site.siteMetadata.title} />
        )) ||
        (location && location.pathname === "/volunteer/" && (
          <VolunteerHeader siteTitle={data.site.siteMetadata.title} />
        )) ||
        (location && location.pathname === "/donate/" && (
          <DonateHeader siteTitle={data.site.siteMetadata.title} />
        )) ||
        (location && location.pathname === "/blog/" && (
          <BlogHeader siteTitle={data.site.siteMetadata.title} />
        )) || <DefaultHeader siteTitle={data.site.siteMetadata.title} />}
      {/* {location && location.pathname === "/about/" && (
        <AboutHeader siteTitle={data.site.siteMetadata.title} />
      )} */}
      {/* {location && location.pathname === "/volunteer/" && (
        <AboutHeader siteTitle={data.site.siteMetadata.title} />
      )} */}
      {/* {location && location.pathname === "/donate/" && (
        <AboutHeader siteTitle={data.site.siteMetadata.title} />
      )} */}
      <main>{children}</main>
      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
