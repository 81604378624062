import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"

import LinkedInIcon from "@images/linkedin-icon.svg"

const MobileSidebarMenu = () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about">About</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/volunteer">Volunteer</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/donate">Donate</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/blog">Blog</Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Link to="/contact">Contact</Link>
      </Nav.Item> */}
      {/* <Nav.Item>
        <Link to="/https://www.fluofoundation.org/">Share</Link>
      </Nav.Item> */}
      <div className="socials-inline mt-3">
        <a
          className="social-icon"
          href="https://www.linkedin.com/company/FLUOFoundation"
        >
          <img src={LinkedInIcon} alt="LinkedIn" />
        </a>
      </div>
    </Nav>
  </div>
)

export default MobileSidebarMenu
