import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import SideBarMenu from "@components/sidebar/sidebar"
import LogoWhite from "@images/FF-white-header-logo.svg"
// import ShareIcon from "@images/share-icon.svg"

import SocialShareModal from "@components/socialShareModal/modal"

const AboutHeader = () => {
  const [scrollY, setScrollY] = useState(0)
  function logit() {
    setScrollY(window.pageYOffset)
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit, { passive: true })
    }
    watchScroll()
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit)
    }
  }, [])

  return (
    <>
      {scrollY > 0 ? (
        <header className="header-bg-blue" style={{ background: "#0C1D43" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-8">
                <Link to="/" className="d-inline-block">
                  <img
                    className="white-logo"
                    src={LogoWhite}
                    alt="Logo White"
                    style={{ width: "220px", height: "auto" }}
                    // className="img-fluid"
                    // style={{ objectFit: "cover" }}
                  />
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 col-4">
                <div className="menu-wrapper d-lg-flex justify-content-end d-md-none d-none">
                  <ul className="list-inline m-0 header-links">
                    <li className="list-inline-item">
                      <Link to="/donate/">Donate</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/about/">About</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/blog/">Blog</Link>
                    </li>
                    <li class="list-inline-item social-share-btn">
                      <SocialShareModal
                        linkToShare="https://www.fluofoundation.org/blog"
                        mclass="social-share-modal list-inline-item social-share-btn"
                      />
                    </li>
                    {/* <li className="list-inline-item social-share-btn">
                      <a href="test">
                        share&nbsp;
                        <img src={ShareIcon} alt="share icon" />
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="d-lg-none d-md-flex d-flex justify-content-end align-items-center">
                  <SideBarMenu />
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header style={{ background: "#0C1D43" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-8">
                <Link to="/" className="d-inline-block">
                  <img
                    className="white-logo"
                    src={LogoWhite}
                    alt="Logo White"
                    style={{ width: "220px", height: "auto" }}
                    // className="img-fluid"
                    // style={{ objectFit: "cover" }}
                  />
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 col-4">
                <div className="menu-wrapper d-lg-flex justify-content-end d-md-none d-none">
                  <ul className="list-inline m-0 header-links">
                    <li className="list-inline-item">
                      <Link to="/donate/">Donate</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/about/">About</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/blog/">Blog</Link>
                    </li>
                    {/* <li className="list-inline-item social-share-btn">
                      <a href="test">
                        share&nbsp;
                        <img src={ShareIcon} alt="share icon" />
                      </a>
                    </li> */}
                    <li class="list-inline-item social-share-btn">
                      <SocialShareModal
                        linkToShare="https://www.fluofoundation.org/blog"
                        mclass="social-share-modal list-inline-item"
                      />
                    </li>
                  </ul>
                </div>
                <div className="d-lg-none d-md-flex d-flex justify-content-end align-items-center">
                  <SideBarMenu />
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  )
}

export default AboutHeader
