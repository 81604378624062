import React, { useEffect, useState } from "react"
import SocialShareModal from "@components/socialShareModal/modal"
import SideBarMenu from "@components/sidebar/sidebar"
// import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link } from "gatsby"

//images and icons
import LogoWhite from "@images/FF-white-header-logo.svg"
// import ShareIcon from "@images/share-icon.svg"
import LinkedInIcon from "@images/linkedin-icon.svg"
// import FacebookIcon from "@images/facebook-icon.svg"
// import InstagramIcon from "@images/instagram-icon.svg"

const HomepageHeader = () => {
  const [scrollY, setScrollY] = useState(0)
  function logit() {
    setScrollY(window.pageYOffset)
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit, { passive: true })
    }
    watchScroll()
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit)
    }
  }, [])

  return (
    <>
      {scrollY > 0 ? (
        <header className="header-bg-blue" style={{ background: "#0C1D43" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-8">
                <Link to="/" className="d-inline-block">
                  <img
                    className="white-logo"
                    src={LogoWhite}
                    alt="Logo White"
                    style={{ width: "220px", height: "auto" }}
                    // className="img-fluid"
                    // style={{ objectFit: "cover" }}
                  />
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 col-4">
                <div className="menu-wrapper d-lg-flex justify-content-end d-md-none d-none">
                  <ul className="list-inline m-0 header-links">
                    <li className="list-inline-item">
                      <Link to="/donate/">Donate</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/about/">About</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/blog/">Blog</Link>
                    </li>
                    <li class="list-inline-item social-share-btn">
                      <SocialShareModal
                        linkToShare="https://www.fluofoundation.org/"
                        mclass="social-share-modal list-inline-item"
                      />
                    </li>
                    {/* <li className="list-inline-item social-share-btn">
                      <a href="test">
                        share&nbsp;
                        <img src={ShareIcon} alt="share icon" />
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="d-lg-none d-md-flex d-flex justify-content-end align-items-center">
                  <SideBarMenu />
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header className="header-bg-transparent">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-4 col-6 align-self-center">
                <div
                  className="d-flex align-items-center"
                  // style={{ height: 65 }}
                >
                  <div className="socials-inline">
                    {/* <a
                      className="social-icon"
                      href="https://www.instagram.com/"
                    >
                      <img src={InstagramIcon} alt="Instagram" />
                    </a> */}
                    {/* <a className="social-icon" href="https://www.facebook.com/">
                      <img src={FacebookIcon} alt="Facebook" />
                    </a> */}
                    <a
                      className="social-icon"
                      href="https://www.linkedin.com/company/FLUOFoundation"
                    >
                      <img src={LinkedInIcon} alt="LinkedIn" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 col-6">
                <div className="menu-wrapper d-lg-flex d-md-flex d-none justify-content-end align-items-center">
                  <ul className="list-inline m-0 header-links">
                    <li className="list-inline-item">
                      <Link to="/donate/">Donate</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/about/">About</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/blog/">Blog</Link>
                    </li>
                    <li className="list-inline-item social-share-btn">
                      <SocialShareModal
                        linkToShare="https://www.fluofoundation.org/"
                        mclass="social-share-modal list-inline-item social-share-btn"
                      />
                      {/* <a href="test">
                        share&nbsp;
                        <img src={ShareIcon} alt="share icon" />
                      </a> */}
                    </li>
                  </ul>
                </div>
                <div className="d-lg-none d-md-none d-flex justify-content-end align-items-center">
                  <SideBarMenu />
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  )
  // <header>
  //   <div className="container">
  //     {/*header links for medium screens and larger*/}
  //     <div className="d-flex justify-content-end">
  //       <ul className="list-inline m-0 header-links">
  //         {/*<li className="list-inline-item">
  //           <AnchorLink href="#blog">Blog</AnchorLink>
  //         </li>*/}
  //         <li className="list-inline-item">
  //           <SocialShareModal
  //             linkToShare="https://fluo-peh.netlify.app/"
  //             mclassName="social-share-modal"
  //           />
  //         </li>
  //       </ul>
  //     </div>
  //     {/*burger menu for small screens
  //     <div className="d-block d-md-none">
  //       <div className="text-right">
  //         <SideBarMenu />
  //       </div>
  //     </div>*/}
  //   </div>
  // </header>
}

export default HomepageHeader
