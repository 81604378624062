import React from "react"
import { Link } from "gatsby"

//form
import WebriQForm from "@webriq/gatsby-webriq-form"

//icons
import LinkedInIcon from "@images/linkedin-icon.svg"
// import FacebookIcon from "@images/facebook-icon.svg"
// import InstagramIcon from "@images/instagram-icon.svg"

const footer = props => {
  return (
    <footer>
      {/* <div className="footer-subscribe py-4">
        <div className="container">
          <div className="text-center d-lg-inline-block d-md-flex align-items-md-center justify-content-md-center justify-content-center text-lg-center">
            <h2
              className="h5 text-white mb-3 mb-md-0 pe-0 pe-md-3 d-inline-block w-50"
              style={{ fontWeight: 700 }}
            >
              Sign-up for Our Newsletter!
            </h2>

            <iframe
              scrolling="no"
              src="https://www.forms.fluofoundation.org/contact-us"
              style={{ border: "0px;", width: "100%", height: "120px" }}
            ></iframe>
          </div>
        </div>
      </div> */}
      <div className="footer-main">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 mb-5 mb-md-0">
              <h2 className="h4 text-white mb-5" style={{ fontWeight: "700" }}>
                Contact Us
              </h2>
               <iframe
                scrolling="no"
                src="https://www.forms.fluofoundation.org/contact-us"
                style={{ border: "0px;", width: "100%", height: "390px" }}
                >
              </iframe>
            </div>
            <div className="col-md-4 col-lg-3 mb-5 mb-md-0">
              <h2 className="h4 text-white mb-5" style={{ fontWeight: "700" }}>
                Connect with Us
              </h2>
              <ul className="contact-details-list">
                <li className="address">
                  1905 15th Street, Unit 4315
                  <br />
                  Boulder, Colorado 80306 
                </li>
                <li className="phone">
                  <a href="tel:722-3586">(720) 722-3586</a>
                </li>
              </ul>
              <div className="mt-5 socials-inline">
                {/* <a className="social-icon" href="www.instagram.com">
                  <img src={InstagramIcon} alt="Instagram Icon" />
                </a> */}
                {/* <a className="social-icon" href="www.facebook.com">
                  <img src={FacebookIcon} alt="Facebook Icon" />
                </a> */}
                <a
                  className="social-icon"
                  href="https://www.linkedin.com/company/FLUOFoundation"
                >
                  <img src={LinkedInIcon} alt="LinkedIn Icon" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <h2 className="h4 text-white mb-5" style={{ fontWeight: "700" }}>
                Quick Links
              </h2>
              <ul className="footer-quick-links">
                <li className="phone">
                  <Link to="/">Home</Link>
                </li>
                <li className="phone">
                  <Link to="/about/">About</Link>
                </li>
                <li className="phone">
                  <Link to="/volunteer/">Volunteer</Link>
                </li>
                <li className="phone">
                  <Link to="/donate/">Donate</Link>
                </li>
                <li className="phone">
                  <Link to="/blog/">Blog</Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="
              footer-copyright
              d-md-flex
              justify-content-md-between
              text-center
            "
          >
            <span className="d-block">
              Copyright © 2014-2021 FLUO. All rights reserved.
            </span>
            <span className="d-block">
              Designed and powered by
              <a
                href="https://www.webriq.com/"
                target="_blank"
                rel="noreferrer"
                className="ms-1"
              >
                WebriQ
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
